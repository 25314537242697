<template>
  <div>
    <b-col class="col-12 col-md-8 p-3 mx-auto">
      <b-card no-body class="p-3 mb-2">
        <validation-observer ref="accountForm" v-slot="{ valid }">
          <b-form>
            <b-row class="no-gutters">
              <b-col class="col-12 col-md-6 pr-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('firstName')"
                  label-for="firstName"
                >
                  <validation-provider
                    :name="$t('firstName')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="firstName"
                      v-model="client.firstName"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-6 pl-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('lastName')"
                  label-for="lastName"
                >
                  <validation-provider
                    :name="$t('lastName')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="lastName"
                      v-model="client.lastName"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-6 pr-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('email')"
                  label-for="email"
                >
                  <validation-provider
                    v-if="formType === 'NEW'"
                    :name="$t('email')"
                    :rules="{ required: true, email: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="email"
                      v-model="client.email"
                      :state="getValidationState(validationContext)"
                      @input="client.email = client.email.toLowerCase()"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ client.email }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-6 pl-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('phone')"
                  label-for="phone"
                >
                  <validation-provider
                    :name="$t('phone')"
                    :rules="{ required: client.type !== 'predict' }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="phone"
                      v-model="client.phone"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-6 pr-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('companyName')"
                  label-for="companyName"
                >
                  <validation-provider
                    :name="$t('companyName')"
                    :rules="{ required: client.type !== 'predict' }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="companyName"
                      v-model="client.companyName"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-6 pl-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('jobTitle')"
                  label-for="jobTitle"
                >
                  <validation-provider
                    :name="$t('jobTitle')"
                    :rules="{ required: client.type !== 'predict' }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="jobTitle"
                      v-model="client.jobTitle"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-4">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('country')"
                  label-for="country"
                >
                  <validation-provider
                    :name="$t('country')"
                    :rules="{ required: client.type !== 'predict' }"
                    v-slot="validationContext"
                  >
                    <b-form-select
                      id="country"
                      v-model="client.country"
                      :options="countryOptions"
                      :state="getValidationState(validationContext)"
                      value-field="name"
                      text-field="name"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled>
                          {{ $t("selectAnOption") }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-4 pl-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('city')"
                  label-for="city"
                >
                  <validation-provider
                    :name="$t('city')"
                    :rules="{ required: client.type !== 'predict' }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="city"
                      v-model="client.city"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-4 pl-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('address')"
                  label-for="address"
                >
                  <validation-provider
                    :name="$t('address')"
                    :rules="{ required: client.type !== 'predict' }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="address"
                      v-model="client.address"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-4 pl-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('province')"
                  label-for="province"
                >
                  <validation-provider
                    :name="$t('province')"
                    :rules="{ required: client.type !== 'predict' }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="province"
                      v-model="client.province"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <div class="mt-3"></div>
              <b-col class="col-12 col-md-4 pl-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('ZIP')"
                  label-for="zip"
                >
                  <validation-provider
                    :name="$t('ZIP')"
                    :rules="{ required: client.type !== 'predict' }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="zip"
                      v-model="client.zip"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-4 pl-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('VAT')"
                  label-for="vat"
                >
                  <validation-provider
                    :name="$t('VAT')"
                    :rules="{ required: client.type !== 'predict' }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="vat"
                      v-model="client.vat"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="client.clientType === 'custom' && client.type !=='predict'"
                class="col-12 col-md-6 pl-md-1"
              >
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('multiUser')"
                  label-for="multiuser"
                >
                  <b-form-checkbox
                    v-model="client.multiUser"
                    switch
                    :disabled="hasBranches"
                  >
                    <span class="text-muted ml-3">
                      {{ $t("enableMultiUser") }}
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-6 pl-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('newsletter')"
                  label-for="newsletter"
                >
                  <b-form-checkbox
                    v-model="client.newsletterRegistration"
                    switch
                  >
                    <span class="text-muted ml-3">
                      {{ $t("newsletterText") }}
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="mt-3"></div>
            <b-row v-if="formType === 'NEW'" class="no-gutters">
              <b-button class="ml-auto" :disabled="!valid" @click="setUser">
                {{ actionBtnLabel }}
              </b-button>
            </b-row>
            <b-row v-else-if="formType === 'EDIT'" class="no-gutters">
              <b-col class="col-auto">
                <b-button
                  class="mr-1 mb-1"
                  variant="danger"
                  @click="openDeleteConfirmationDialog"
                >
                  {{ $t("button.delete") }}
                </b-button>
              </b-col>
              <b-col class="col-auto ml-auto mr-1 mb-1">
                <b-button variant="outline-primary" @click="cancel">
                  {{ $t("button.cancel") }}
                </b-button>
              </b-col>
              <b-col class="col-auto mr-1 mb-1">
                <b-button :disabled="!valid" @click="setUser">
                  {{ actionBtnLabel }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import Utils from "./../../Utils";
export default {
  props: {
    formType: {
      type: String,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  computed: {
    actionBtnLabel() {
      return this.formType === "NEW"
        ? this.$t("button.createAccount")
        : this.$t("button.updateAccount");
    },
    countryOptions() {
      return Utils.COUNTRY_LIST;
    },
    hasBranches() {
      return this.client.branches.length > 0;
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null, untouched }) {
      // if (!untouched && (dirty || validated)) return valid ? null : valid;
      // else return null;
      if (!untouched) return valid ? null : valid;
      else return null;
    },
    setUser() {
      this.$emit("setClient", this.client);
    },
    openDeleteConfirmationDialog() {
      const clientFullName =
        this.client.firstName && this.client.lastName
          ? `${this.client.firstName} ${this.client.lastName}`
          : null;
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "dialog.confirm.deleteClientTitle",
        messageText: "dialog.confirm.deleteClientMessage",
        messageParams: { clientFullName },
        confirmBtnCallback: this.deleteUser,
        type: "DELETE",
      });
    },
    deleteUser() {
      this.$emit("deleteClient", this.client._id);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
