<template>
  <div>
    <base-page-title :titleText="pageTitle" />
    <client-form
      :formType="formType"
      :client="client"
      @setClient="updateClientAccount"
      @deleteClient="deleteClientAccount"
      @cancel="goToClientsPage"
    />
    <div v-if="isClientMultiuser">
      <b-col class="col-12 col-md-8 p-3 mx-auto">
        <b-card no-body class="p-3 mb-2">
          <div class="d-flex align-items-center">
            <img src="./../assets/icons/branches.svg" class="icon mr-1" />
            <h6 class="font-weight-bold mb-0">{{ $t("branches") }}</h6>
          </div>
          <hr />
          <div v-for="branch in branchList" :key="branch.id">
            <client-branch-list-item
              :clientId="clientId"
              :branch="branch"
              :users="branchUsersList(branch._id)"
            />
          </div>
          <div v-if="isEmptyBranchList">
            <h5 class="text-center">
              {{ $t("emptyList") }}
            </h5>
          </div>
        </b-card>
      </b-col>
      <b-col class="col-12 col-md-8 p-3 mx-auto">
        <b-card no-body class="p-3 mb-2">
          <div class="d-flex align-items-center">
            <img src="./../assets/icons/user.svg" class="icon mr-1" />
            <h6 class="font-weight-bold mb-0">{{ $t("page.users.title") }}</h6>
          </div>
          <hr />
          <div v-for="user in userList" :key="user.id">
            <client-user-list-item
              :user="user"
              :branch="branchDetails(user.branchId)"
            />
          </div>
          <div v-if="isEmptyUserList">
            <h5 class="text-center">
              {{ $t("emptyList") }}
            </h5>
          </div>
        </b-card>
      </b-col>
    </div>
  </div>
</template>

<script>
import ClientForm from "./../components/Client/ClientForm";
import ClientBranchListItem from "./../components/Client/ClientBranchListItem.vue";
import ClientUserListItem from "./../components/Client/ClientUserListItem.vue";

export default {
  components: {
    ClientForm,
    ClientBranchListItem,
    ClientUserListItem,
  },
  data() {
    return {
      formType: "EDIT",
      pageTitle: "account.edit.client",
    };
  },
  computed: {
    client() {
      const client = {
        ...this.$store.getters["clients/getActiveClient"],
      };
      client.email = client.registration?.email
        ? client.registration.email
        : "-";
      client.type = client.registration?.type
        ? client.registration.type
        : 'client'

      return client;
    },
    clientId() {
      return this.client._id;
    },
    isClientMultiuser() {
      return this.client.multiUser;
    },
    branchList() {
      return this.client.branches;
    },
    userList() {
      const users = [...this.$store.getters["clients/getActiveClientUserList"]];
      const sortedUsersBySubTypeDesc = users.sort((a, b) => {
        const fa = a.subType.toLowerCase();
        const fb = b.subType.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      return sortedUsersBySubTypeDesc;
    },
    isEmptyBranchList() {
      return this.branchList.length === 0;
    },
    isEmptyUserList() {
      return this.userList.length === 0;
    },
  },
  methods: {
    updateClientAccount(client) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("clients/updateClient", client)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    deleteClientAccount(clientId) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("clients/deleteClient", clientId)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    goToClientsPage() {
      this.$router.push({ name: "Clients" });
    },
    branchUsersList(branchId) {
      return this.userList.filter((user) => {
        return user.branchId === branchId;
      });
    },
    branchDetails(branchId) {
      return this.client.branches.find((branch) => {
        return branch._id === branchId;
      });
    },
  },
};
</script>

<style scoped>
.icon {
  width: 20px;
  height: 20px;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg)
    brightness(87%) contrast(113%);
}
</style>
